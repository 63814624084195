import { Modal, List,Typography,Button } from "antd";
import { useEffect, useState } from "react";
import { _getCountryCodes } from "services/AppConstantsService";
import IntlMessage from "components/util-components/IntlMessage";

const SelectLanguageModal = ({ isOpen, onCancelTrigger, onItemSelected }) => {
  const [languageLocalization, setLanguageLocalization] = useState([]);

  useEffect(() => {
    fetch()
  }, []);

  let fetch = async() => {
    try {
      let countryData = await _getCountryCodes();
      if (countryData.status == 200) {
        setLanguageLocalization(countryData.data);
      } else {
        setLanguageLocalization([]);
      }
    } catch (error) {
      setLanguageLocalization([]);
    }
  }

  return (
    <Modal
      style={{ width: 200, height: 400 }}
      title={<IntlMessage id="content-language-select" />}
      visible={isOpen}
      onCancel={() => onCancelTrigger()}
      footer={[
        <Button onClick={onCancelTrigger}>Cancel</Button>
      ]}
    >
      <List
        dataSource={languageLocalization}
        renderItem={(item) => (
          <List.Item onClick={() => onItemSelected(item.code)}>
            <Typography.Text style={{cursor:"pointer"}}> {item.name}</Typography.Text> 
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default SelectLanguageModal;
