import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
  showLoading,
  ownerCreateSuccess,
  ownerCreatedDone,
} from "../actions/Auth";

import FirebaseService from "services/FirebaseService";
import JwtAuthService from "services/JwtAuthService";
import { notification } from "antd";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(JwtAuthService.login, { email, password });
      if (user.token) {
        localStorage.setItem(AUTH_TOKEN, user.token);
        localStorage.setItem("ROLES", user.user.roles);
        localStorage.setItem("USER_ID", user.user._id);
        yield put(authenticated({ token: user.token, user: user.user }));
      } else {
        yield put(showAuthMessage("Incorrect Authentication Credentials"));
        notification.error({
          message: "User Not Found",
          description: "Incorrect Authentication Credentials",
        });
      }
    } catch (err) {
      // yield put(showAuthMessage(err));
      notification.error({
        message: "User Not Found",
        description: "Incorrect Authentication Credentials",
      });
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signUpForOwner() {
  yield takeEvery("SIGNUP_OWNER", function* ({ payload }) {
    try {
      yield put(showLoading());
      yield call(JwtAuthService.registerOwner, payload);
      yield put(ownerCreateSuccess());
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Create Owner Error",
        description: error.message,
      });
    }
    yield put(ownerCreatedDone());
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
    fork(signUpForOwner),
  ]);
}
