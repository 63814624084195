import { PageHeader,Button } from "antd"; 

const SubHeader = ({title, buttonText, buttonOnClick}) => {
  return (
    <PageHeader
        style={{
          border: "1px solid rgb(235, 237, 240)",
          marginBottom:10,
          borderRadius:10
        }}
        onBack={() => window.history.back()}
        title={title}
        extra={[
            <Button type="primary" onClick={() => buttonOnClick()}>
              {buttonText}
            </Button>
          ]}
      />
  )
}

export default SubHeader;
