import { useState, useEffect } from "react";
import { Card, Table, Tooltip, Button, Modal } from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EditSideModal from "./components/editSideModal";
import SubHeader from "components/subHeader";
import CreateSideModal from "./components/createSideModal";
import IntlMessage from "components/util-components/IntlMessage";
import { useTag } from "hooks";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";

const { confirm } = Modal;

const Interest = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const intl = useIntl();
  const { getTags, loading, error, data, deleteTag } = useTag();

  useEffect(() => {
    if (params.get("page") == null) {
      getTags({});
    } else {
      getTags({ page: params.get("page") });
    }
  }, []);

  const [tagDetail, setTagDetail] = useState(false);
  const [tagCreate, setTagCreate] = useState(false);
  const [updateId, setUpdateId] = useState("");

  let deleteTagTrigger = (id) => {
    confirm({
      title: intl.formatMessage({ id: "deleteTitle" }),
      icon: <ExclamationCircleFilled />,
      okText: intl.formatMessage({ id: "deleteOkeyButton" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "deleteCancelButton" }),
      onOk() {
        deleteTag(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  let showTagDetails = (id) => {
    setUpdateId(id);
    setTagDetail(true);
  };

  let closeTagDetail = () => {
    setUpdateId("");
    setTagDetail(false);
  };

  let showTagCreate = () => {
    setTagCreate(true);
  };

  let closeTagCreate = () => {
    setTagCreate(false);
  };

  const tableColumns = [
    {
      title: <IntlMessage id="tag-list-image" />,
      dataIndex: "image",
      render: (_, record) => (
        <div className="d-flex">
          <AvatarStatus src={record?.image?.url} />
        </div>
      ),
    },
    {
      title: <IntlMessage id="tag-list-name" />,
      dataIndex: "name",
    },
    {
      title: <IntlMessage id="tag-list-description" />,
      dataIndex: "description",
    },
    {
      title: <IntlMessage id="tag-list-created-by" />,
      dataIndex: "createdBy",
      render: (item) => (item ? `${item?.name} ${item?.surname}` : ""),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title="View">
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => showTagDetails(elm._id)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => deleteTagTrigger(elm._id)}
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  let onPageChange = (e) => {
    history.push(`/panel/tags?page=${e}`);
  };

  return (
    <div>
      <SubHeader
        title={<IntlMessage id="tag-page-title" />}
        buttonText={<IntlMessage id="tag-add-button-text" />}
        buttonOnClick={showTagCreate}
      />
      <Card bodyStyle={{ padding: "0px" }}>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={data.data}
            rowKey="id"
            loading={loading}
            pagination={{
              pageSize: 10,
              total: data?.meta?.totalCount,
              current: data?.meta?.page,
              onChange: onPageChange,
            }}
          />
        </div>
        {tagDetail ? (
          <EditSideModal
            visible={tagDetail}
            close={() => closeTagDetail()}
            id={updateId}
          />
        ) : null}
        <CreateSideModal visible={tagCreate} close={() => closeTagCreate()} />
      </Card>
    </div>
  );
};

export default Interest;
