import fetch from "auth/FetchInterceptor";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

JwtAuthService.registerOwner = function (data) {
  return fetch({
    url: "/auth/register-owner",
    method: "post",
    data: data,
  });
};

export default JwtAuthService;
