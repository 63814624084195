import { useState } from "react";
import fetch from "auth/FetchInterceptor";
import { notification } from "antd";

export const useUser = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false)
  const [singleUser, setSingleUser] = useState({})

  const getUsers = async (pageData) => {
    setLoading(true);
    try {
      setData(await fetch({
        url: `/user`,
        method: "get",
        params: pageData,
      }));
    } catch (error) {
      setData({data:[],meta:{}});
      setError(false);
    }
    setLoading(false);
  };

  const registerOwner = async (credentials) => {
    setLoading(true);
    try {
      await fetch({
        url: "/auth/register-owner",
        method: "post",
        data: credentials,
      });

      window.location.href = "/panel/owner";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  const getUser = async (userId) => {
    setLoading(true)
    setDone(false);
    try {
      setSingleUser(
        await fetch({
          url:`/user/${userId}`,
          method:"get"
        })
      )
      setDone(true)
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
      setDone(false);
    }
    setLoading(false)
  }

  const updateUser = async (userData, id,base) => {
    setLoading(true)
    try {
      await fetch({
        url:`/user/${id}`,
        method:'put',
        data:userData
      })

      window.location.href = `/panel/${base}`
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false)
  }

  const deleteUser = async(id,base) => {
    setLoading(true)
    try {
      await fetch({
        url:`/user/${id}`,
        method:"delete"
      })

      window.location.href = `/panel/${base}`
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false)
  }

  return {
    loading,
    data,
    error,
    getUsers,
    registerOwner,
    getUser,
    done,
    singleUser,
    updateUser,
    deleteUser
  };
};
