import { useState } from "react";
import fetch from "auth/FetchInterceptor";
import { notification } from "antd";

export const useTag = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} || []);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [singleTag, setSingleTag] = useState(null);

  const getTags = async (pagination) => {
    setLoading(true);
    try {
      setData(
        await fetch({
          url: `/tag`,
          method: "get",
          params: pagination,
        })
      );
    } catch (error) {
      setData([]);
      setError(false);
    }
    setLoading(false);
  };

  const getAllTags = async () => {
    setLoading(true);
    try {
      setData(
        await fetch({
          url: `/tag/get-tags-by-client`,
          method: "get",
        })
      );
    } catch (error) {
      setData([]);
      setError(false);
    } finally {
      setLoading(false);
    }
  };

  const createTag = async (tagData) => {
    setLoading(true);
    try {
      await fetch({
        url: "/tag/create",
        method: "post",
        data: tagData,
      });

      window.location.href = "/panel/tags";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  const deleteTag = async (id) => {
    setLoading(true);
    try {
      await fetch({
        url: `/tag/${id}`,
        method: "delete",
      });

      window.location.href = "/panel/tags";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  const getTag = async (id) => {
    setLoading(true);
    setDone(false);
    try {
      setSingleTag(
        await fetch({
          url: `/tag/${id}`,
          method: "get",
          headers: {
            lang: "all",
          },
        })
      );
      setDone(true);
    } catch (error) {
      setSingleTag(null);
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
      setDone(false);
    }
    setLoading(false);
  };

  const updateTag = async (tagData, id) => {
    setLoading(true);
    try {
      await fetch({
        url: `/tag/${id}`,
        method: "put",
        data: tagData,
      });

      window.location.href = "/panel/tags";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  return {
    loading,
    data,
    error,
    singleTag,
    getTags,
    createTag,
    deleteTag,
    getTag,
    updateTag,
    getAllTags,
    done,
  };
};
