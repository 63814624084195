import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Loading from "components/shared-components/Loading";

export default function PrivateRoute() {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path="/panel/users"
          component={lazy(() => import(`../views/dashboard-views/users/list`))}
        />
        <Route
          path="/panel/tags"
          component={lazy(() => import(`../views/dashboard-views/tags`))}
        />
        <Route
          path="/panel/activity/add"
          component={lazy(() =>
            import(`../views/dashboard-views/activities/add`)
          )}
        />
        <Route
          path="/panel/activity/edit/:id"
          component={lazy(() =>
            import(`../views/dashboard-views/activities/edit`)
          )}
        />
        <Route
          path="/panel/activity"
          component={lazy(() =>
            import(`../views/dashboard-views/activities/list`)
          )}
        />
        <Route
          path="/panel/business/add"
          component={lazy(() =>
            import(`../views/dashboard-views/business/add`)
          )}
        />
        <Route
          path="/panel/business/edit/:id"
          component={lazy(() =>
            import(`../views/dashboard-views/business/edit`)
          )}
        />
        <Route
          path="/panel/business"
          component={lazy(() => import(`../views/dashboard-views/business`))}
        />
        <Route
          path="/panel/owner"
          component={lazy(() => import(`../views/dashboard-views/owner/list`))}
        />
        <Route
          path="/panel/home"
          component={lazy(() => import(`../views/dashboard-views/home`))}
        />
      </Switch>
    </Suspense>
  );
}
