import { useState } from "react";
import fetch from "auth/FetchInterceptor";
import { notification } from "antd";

export const useBusiness = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [singleBusiness, setSingleBusiness] = useState({});
  const [done, setDone] = useState(false);

  const getBusinessAll = async (pagination) => {
    //todo add pagination
    setLoading(true);
    setDone(false);
    try {
      setData(
        await fetch({
          url: "/business",
          method: "get",
          params: pagination,
        })
      );
    } catch (error) {
      console.log(error);
      setData([]);
    }
    setLoading(false);
    setDone(true);
  };

  const createBusiness = async (businessData) => {
    setLoading(true);
    try {
      await fetch({
        url: "/business/create",
        method: "post",
        data: businessData,
      });
      window.location.href = "/panel/business";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  const getBusiness = async (id) => {
    setLoading(true);
    setDone(false);
    try {
      setSingleBusiness(
        await fetch({
          url: `/business/${id}`,
          method: "get",
          headers: {
            lang: "all",
          },
        })
      );
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
      setDone(false);
    }
    setDone(true);
    setLoading(false);
  };

  const updateBusiness = async (businessData, id) => {
    setLoading(true);
    try {
      await fetch({
        url: `/business/${id}`,
        method: "put",
        data: businessData,
      });
      window.location.href = "/panel/business";
    } catch (error) {
      console.log(error?.response?.data?.message.toString())
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  const deleteBusiness = async (id) => {
    setLoading(true);
    try {
      await fetch({
        url: `/business/${id}`,
        method: "delete",
      });

      window.location.href = "/panel/business";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
    setLoading(false);
  };

  return {
    loading,
    data,
    getBusinessAll,
    createBusiness,
    getBusiness,
    updateBusiness,
    done,
    singleBusiness,
    setSingleBusiness,
    deleteBusiness
  };
};
