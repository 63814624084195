import {useState} from 'react'
import fetch from "auth/FetchInterceptor";

export const useHome = () => {
    const [loading, setLoading] = useState(false)
    const [homeData, setHomeData] = useState({})

    const getHomeData = async () => {
        setLoading(true)
        try {
            setHomeData(
                await fetch({
                    url: `/home-data`,
                    method: "get",
                })
            )

        } catch (error) {
            setHomeData({})
            console.log("error:",error)
        }
        setLoading(false)
    }


    return {
        loading,
        homeData,
        getHomeData
    }
}