import { PHONE_CODES,COUNTRY_LOCALIZATION } from "configs/AppConfig";
import axios from "axios";

const appConstantsService = {};

export const _getPhoneCodes = async () => {
  const { data, status } = await axios({
    url: PHONE_CODES,
    method: "get",
  });
  return { data, status };
};

export const _getCountryCodes = async () => {
    const { data, status } = await axios({
      url: COUNTRY_LOCALIZATION,
      method: "get",
    });
    return { data, status };
  };

export default appConstantsService;
