import { Button } from "antd"


const CancelCreateButtons = ({cancelOnClick, saveOnClick, loading}) => {

    return (
        <div style={{display:"flex", alignItems:"center",justifyContent:"center",marginTop:20}}>
            <Button type="secondary" block style={{marginRight:2}} loading={loading} onClick={() => cancelOnClick()}>
                Cancel
            </Button>
            
            <Button type="primary" block style={{marginLeft:2}} loading={loading} onClick={() => saveOnClick()}>
                Save
            </Button>
        </div>
    )
}

export default CancelCreateButtons