import {useState} from 'react'
import { notification } from "antd";
import fetch from "auth/FetchInterceptor";

export const useUpload = () => {
    const [loadingUpload, setLoadingUpload] = useState(false)

    const uploadSingle = async(file) => {
        setLoadingUpload(true)
        try {

                let singleImage = await fetch({
                    url:"/upload/single",
                    method:"post",
                    data:file
                },
                {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                return singleImage
        } catch (error) {
            notification.error({
                message: error?.response?.data?.error,
                description: error?.response?.data?.message.toString(),
              });
        }
        setLoadingUpload(false)
    }

    const uploadMultiple = async(files) => {
        setLoadingUpload(true)
        try {
            let multipleImages = await fetch({
                url:"/upload/multiple",
                method:"post",
                data:files
            },
            {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            return multipleImages
        } catch (error) {
            notification.error({
                message: error?.response?.data?.error,
                description: error?.response?.data?.message.toString(),
              });
        }
        setLoadingUpload(false)
    }

    return {
        loadingUpload,
        uploadSingle,
        uploadMultiple
    }
}