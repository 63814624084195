import { Avatar, Drawer, Divider, Input, Button } from "antd";
import CancelCreateButtons from "components/cancelCreateButtonGroup";
import IntlMessage from "components/util-components/IntlMessage";
import { useState, useEffect } from "react";
import SelectLanguageModal from "components/selectLanguageModal";
import { useTag, useUpload } from "hooks";
import { PlusCircleFilled,DeleteOutlined } from "@ant-design/icons";

const EditSideModal = ({ visible, close, id }) => {
  const { loading, getTag, singleTag, done, updateTag } = useTag();
  const { loadingUpload, uploadSingle } = useUpload();

  useEffect(() => {
    getTag(id);
  }, [id]);

  useEffect(() => {
    if (done) {
      setVariantTitle(singleTag.name);
      setVariantDescription(singleTag.description);
    }
  }, [done]);

  const [variantTitle, setVariantTitle] = useState({ en: "" });
  const [variantDescription, setVariantDescription] = useState({ en: "" });
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null)

  let addNewLanguageVariant = (langKey) => {
    variantTitle[langKey] = "";
    setVariantTitle({ ...variantTitle });

    variantDescription[langKey] = "";
    setVariantDescription({ ...variantDescription });

    setIsLanguageModalOpen(false);
  };

  let onDataChanged = (e, element, type) => {
    if (type === "title") {
      variantTitle[element] = e;
      setVariantTitle({ ...variantTitle });
    } else {
      variantDescription[element] = e;
      setVariantDescription({ ...variantDescription });
    }
  };

  let discardVariant = (langKey) => {
    delete variantTitle[langKey]
    setVariantTitle({...variantTitle})

    delete variantDescription[langKey]
    setVariantDescription({...variantDescription})
  }

  let onSubmit = async() => {
    let customImageId = singleTag?.image?._id ? singleTag.image._id : null
    if(selectedImage){
      try {
        const formData = new FormData();
        formData.append("file", selectedImage)
        let res = await uploadSingle(formData)
        customImageId = res._id
      } catch (error) {
        customImageId = singleTag?.image?._id ? singleTag.image._id : null
      }
    }
    updateTag(
      {
        name: variantTitle,
        description: variantDescription,
        image: customImageId,
      },
      id
    );
  };

  return (
    <Drawer
      width={400}
      placement="right"
      onClose={close}
      closable={false}
      visible={visible}
      loading={loading}
    >
      <div className="text-center mt-3">
      <input type="file" id="file-input" style={{ display: "none" }} onChange={(event) => setSelectedImage(event.target.files[0])}/>
        <label htmlFor="file-input">
          <Avatar
            style={{ cursor: "pointer" }}
            shape="square"
            size={100}
            src={selectedImage ? URL.createObjectURL(selectedImage) : (done && singleTag?.image?.url != undefined ? singleTag?.image?.url : "")}
            onMouseEnter={() => setHoverImage(true)}
            onMouseLeave={() => setHoverImage(false)}
            icon={hoverImage ? <PlusCircleFilled /> : null}
          />
        </label>
        <h3 className="mt-2 mb-0">
          <IntlMessage id="tag-update-modal-title" />
        </h3>
      </div>
      <Divider dashed />
      <div className="">
        <div
          style={{
            display: "flex",
            alingItem: "center",
            flexDirection: "row",
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          <h4 className="mt-1">
            <IntlMessage id="default-name-plural" />
          </h4>
        </div>
        {Object.keys(variantTitle).map((element) => {
          return (
            <p>
              <span className="text-grey">{element}</span>
              <Input
                placeholder={element.name}
                value={variantTitle[element]}
                onChange={(e) =>
                  onDataChanged(e.target.value, element, "title")
                }
                addonAfter={element == "en" ? null: <DeleteOutlined onClick={() => discardVariant(element)} />}
              />
            </p>
          );
        })}
        <Button
          type="dashed"
          block
          size="small"
          onClick={() => setIsLanguageModalOpen(true)}
        >
          <IntlMessage id="default-add-new-variant" />
        </Button>
        <SelectLanguageModal
          isOpen={isLanguageModalOpen}
          onCancelTrigger={() => setIsLanguageModalOpen(false)}
          onItemSelected={(val) => addNewLanguageVariant(val)}
        />
      </div>
      <Divider dashed />
      <div className="">
        <div
          style={{
            display: "flex",
            alingItem: "center",
            flexDirection: "row",
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          <h4 className="mt-1">
            <IntlMessage id="default-description-plural" />
          </h4>
        </div>
        {Object.keys(variantDescription).map((element) => {
          return (
            <p>
              <span className="text-grey">{element}</span>
              <Input
                placeholder={element.name}
                value={variantDescription[element]}
                onChange={(e) =>
                  onDataChanged(e.target.value, element, "description")
                }
              />
            </p>
          );
        })}
      </div>
      <Divider dashed />
      <div className="mt-5">
        <CancelCreateButtons
          cancelOnClick={close}
          saveOnClick={() => onSubmit()}
          loading={loading}
        />
      </div>
    </Drawer>
  );
};

export default EditSideModal;
