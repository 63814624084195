import {
  RocketOutlined,
  BookOutlined,
  UserSwitchOutlined,
  HomeOutlined,
  ShopOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const mainSidebarStack = [{
  key: 'main',
  path: `${APP_PREFIX_PATH}/panel`,
  title: 'sidenav-main-title',
  icon: BookOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'main-homepage',
      path: `/panel/home`,
      title: 'main-homepage-title',
      icon: HomeOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'main-business',
      path: `/panel/business`,
      title: 'main-business-title',
      icon: ShopOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'main-tag',
      path: `/panel/tags`,
      title: 'main-tag-title',
      icon: TagsOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'main-activity',
      path: `/panel/activity`,
      title: 'main-activity-title',
      icon: RocketOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'owner',
      path: `/panel/owner`,
      title: 'owner',
      icon: UserOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'user',
      path: `/panel/users`,
      title: 'main-user-title',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  // ...dashBoardNavTree,
  // ...appsNavTree,
  // ...componentsNavTree,
  // ...extraNavTree,
  // ...docsNavTree,
  ...mainSidebarStack
]

export default navigationConfig;
