import { useState } from "react";
import fetch from "auth/FetchInterceptor";
import { notification } from "antd";

export const useActivity = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getActivities = async ({ page, take }) => {
    setLoading(true);
    try {
      const activityData = await fetch({
        url: "/activity",
        method: "get",
        params: { page, take },
        headers: { lang: "en" },
      });
      setData(activityData);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  const addActivity = async (body) => {
    setLoading(true);
    try {
      await fetch({
        url: "/activity/create",
        method: "post",
        data: body,
      });

      window.location.href = "/panel/activity";
      //use history
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  const editActivity = async (id, body) => {
    setLoading(true);
    try {
      await fetch({
        url: `/activity/${id}`,
        method: "PUT",
        data: body,
      });
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  const getActivity = async (id) => {
    setLoading(true);
    try {
      const activityData = await fetch({
        url: `/activity/${id}`,
        method: "GET",
        headers: { lang: "all" },
      });
      setData(activityData);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteActivity = async (id) => {
    try {
      await fetch({
        url: `/activity/${id}`,
        method: "delete",
      });
      window.location.href = "/panel/activity";
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.message.toString(),
      });
    }
  };

  return {
    data,
    loading,
    error,
    getActivities,
    addActivity,
    editActivity,
    getActivity,
    deleteActivity,
    setData,
  };
};
