import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export default function PublicRoute() {
    return (
        <Suspense fallback={<Loading cover="page" />}>
            <Switch>
                <Route path={`${AUTH_PREFIX_PATH}/login` } component={lazy(() => import(`../views/auth-views/authentication/login-1`))} />
            </Switch>
        </Suspense>
    )
}